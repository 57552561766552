import * as formMapConfig from './reducers/map-config.reducer'
import * as fromGeo from './reducers/geo.reducer'
import * as fromAuth from './reducers/auth.reducer'
import * as fromUsers from './reducers/users.reducer'
import * as fromMapFiles from './reducers/map-files.reducer'
import {ActionReducerMap, createFeatureSelector, createSelector} from "@ngrx/store";

export interface State {
  mapConfig: formMapConfig.State;
  geo: fromGeo.State,
  auth: fromAuth.State,
  users: fromUsers.State,
  mapFiles: fromMapFiles.State
}

export const reducers: ActionReducerMap<State> = {
  mapConfig: formMapConfig.reducer,
  geo: fromGeo.reducer,
  auth: fromAuth.reducer,
  users: fromUsers.reducer,
  mapFiles: fromMapFiles.reducer
};

