import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {SharedMaterialModule} from './shared-material.module';
import {SharedPrimeNgModule} from './shared-prime-ng.module';
import {MapDisplayComponent} from "./map-display/map-display.component";
import {NgxMapboxGLModule} from "ngx-mapbox-gl";
import {PolygonPopupComponent} from "./polygon-popup/polygon-popup.component";
import {MapDataTableComponent} from "./map-data-table/map-data-table.component";
import {NewDataCenterPopupComponent} from "./new-data-center-popup/new-data-center-popup.component";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {GeoFilterComponent} from "./geo-filter/geo-filter.component";
import {ProvidersTableComponent} from "./providers-table/providers-table.component";
import {
  ProvidersDatacentersDetailsComponent
} from "./providers-datacenters-details/providers-datacenters-details.component";
import {MapMarkersComponent} from "./map-markers/map-markers.component";
import {OnlyNumberDirective} from "../core/directives/only-number.directive";
import {UserCardComponent} from "./user-card/user-card.component";
import {UsersListComponent} from "./users-list/users-list.component";
import {KeyFilterModule} from "primeng/keyfilter";
import {LocationDirective} from "../core/directives/location.directive";

import { ResizableModule } from 'angular-resizable-element';
import { LoadingComponent } from './loading/loading.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NetworksTableComponent } from './networks-table/networks-table.component';
import { LandTableComponent } from './land-table/land-table.component';
import { PowerTableComponent } from './power-table/power-table.component';
import { AvailabilityZonePopupComponent } from './availability-zone-popup/availability-zone-popup.component';
import { AvailabilityZonesSidebarComponent } from './availability-zone-table/availability-zones-sidebar.component';
import { CheckboxModule } from 'primeng/checkbox';
import {FilesTableComponent} from "./files-table/files-table.component";
import { AvailabilityZoneCircleComponent } from './availability-zone-circles/availability-zone-circle.component';

@NgModule({
  declarations: [
    MapDisplayComponent,
    PolygonPopupComponent,
    MapDataTableComponent,
    NewDataCenterPopupComponent,
    GeoFilterComponent,
    ProvidersTableComponent,
    ProvidersDatacentersDetailsComponent,
    MapMarkersComponent,
    OnlyNumberDirective,
    UserCardComponent,
    UsersListComponent,
    LocationDirective,
    LoadingComponent,
    NetworksTableComponent,
    LandTableComponent,
    PowerTableComponent,
    AvailabilityZonePopupComponent,
    AvailabilityZonesSidebarComponent,
    FilesTableComponent,
    AvailabilityZoneCircleComponent
  ],
  imports: [
    SharedMaterialModule,
    SharedPrimeNgModule,
    ButtonModule,
    FormsModule,
    CommonModule,
    NgxMapboxGLModule,
    KeyFilterModule,
    ResizableModule,
    ProgressSpinnerModule,
    CheckboxModule
  ],
  exports: [
    SharedMaterialModule,
    SharedPrimeNgModule,
    ButtonModule,
    FormsModule,
    MapDisplayComponent,
    PolygonPopupComponent,
    MapDataTableComponent,
    NewDataCenterPopupComponent,
    GeoFilterComponent,
    ProvidersTableComponent,
    ProvidersDatacentersDetailsComponent,
    MapMarkersComponent,
    OnlyNumberDirective,
    UserCardComponent,
    UsersListComponent,
    LocationDirective,
    LoadingComponent,
    NetworksTableComponent,
    LandTableComponent,
    PowerTableComponent,
    AvailabilityZonePopupComponent,
    AvailabilityZonesSidebarComponent,
    CheckboxModule,
    FilesTableComponent,
    AvailabilityZoneCircleComponent
  ]

})
export class SharedModule {
}
