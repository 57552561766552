<div class="popup-container">
  <div class="popup-header">
    <div class="modification-info" *ngIf="data?.modificationDate || data?.creationDate || mapCreationDate">
      <span>
        {{ data?.modificationDate ? 'Updated' : 'Created' }} 
        {{ (data?.modificationDate || data?.creationDate || mapCreationDate) | date:'d MMMM yyyy HH:mm' }}
        <ng-container *ngIf="data?.modifiedBy">
          by {{modifiedByDisplayName || data.modifiedBy}}
        </ng-container>
        <ng-container *ngIf="!data?.modificationDate && !data?.creationDate && mapCreationDate">
          (Map creation date)
        </ng-container>
      </span>
    </div>
  </div>
  <form class="form" #dataForm="ngForm">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="providerTxt">Provider</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <input class="form-control" type="text" pInputText id="providerTxt" *ngIf="isAddingProvider"
                         [(ngModel)]="data.provider" name="provider" required/>
                  <p-dropdown *ngIf="!isAddingProvider" placeholder="Select provider" [options]="providers"
                              [(ngModel)]="selectedProvider" [required]="true"
                              name="exProvider" [style]="{'width':'100%'}"></p-dropdown>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" [(ngModel)]="isAddingProvider" name="addProviderCb" id="addProviderCb"/>
                  <label for="addProviderCb">Add Provider</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="marketTxt">Market</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <input class="form-control" type="text" pInputText id="marketTxt" *ngIf="isAddingMarket"
                         [(ngModel)]="data.market" name="market" required/>
                  <p-dropdown *ngIf="!isAddingMarket" placeholder="Select market" [options]="markets"
                              [(ngModel)]="selectedMarket" [required]="true"
                              name="exMarket"
                              [style]="{'width':'100%'}"></p-dropdown>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" [(ngModel)]="isAddingMarket" name="addMarketCb" id="addMarketCb"/>
                  <label for="addMarketCb">Add Market</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="buildingTenantTxt">Building Tenant</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <input class="form-control" type="text" pInputText id="buildingTenantTxt" *ngIf="isAddingBuildingTenant"
                         [(ngModel)]="data.buildingTenant" name="buildingTenant" required/>
                  <p-dropdown *ngIf="!isAddingBuildingTenant" placeholder="Select building tenant" [options]="buildingTenants"
                              [(ngModel)]="selectedBuildingTenant"
                              name="exBuildingTenant" [style]="{'width':'100%'}"></p-dropdown>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" [(ngModel)]="isAddingBuildingTenant" name="addBuildingTenantCb" id="addBuildingTenantCb"/>
                  <label for="addBuildingTenantCb">Add Building Tenant</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="mapIdTxt">Map id</label>
          <input class="form-control" type="text" pInputText id="mapIdTxt"
                 [(ngModel)]="data.mapId" maxlength="3" name="mapId"/>
        </div>
        <div class="form-group" *ngIf="isModifying">
          <label>Lat</label>
          <p-inputNumber class="form-control" inputId="latTxt" [required]="true" mode="decimal"
                         [minFractionDigits]="6"
                         [maxFractionDigits]="6"
                         [min]="-90"
                         [max]="90"
                         [format]="false"
                         [(ngModel)]="data.lat" name="latTxt"></p-inputNumber>
        </div>
        <div class="form-group" *ngIf="isModifying">
          <label>Lon</label>
          <p-inputNumber class="form-control" inputId="lonTxt" [required]="true" mode="decimal"
                         [minFractionDigits]="6"
                         [maxFractionDigits]="6"
                         [min]="-180"
                         [max]="180"
                         [format]="false"
                         [(ngModel)]="data.lon" name="lonTxt"></p-inputNumber>
        </div>
        <div class="form-group">
          <label for="buildingTxt">Building</label>
          <input class="form-control" type="text" pInputText id="buildingTxt"
                 [(ngModel)]="data.building" name="building"/>
        </div>
        <div class="form-group">
          <label for="buildingTxt">Area</label>
          <input class="form-control" type="text" pInputText id="areaTxt"
                 [(ngModel)]="data.area" name="area"/>
        </div>
        <div class="form-group">
          <label for="mwLiveTxt">MW Live</label>
          <input class="form-control" type="number" pInputText id="mwLiveTxt"
                 [(ngModel)]="data.mwLive" name="mwLive"/>
        </div>
        <div class="form-group">
          <label for="mwUCTxt">MW U/C</label>
          <input class="form-control" type="number" pInputText id="mwUCTxt"
                 [(ngModel)]="data.mwUC" name="mwUC"/>
        </div>
        <div class="form-group">
          <label for="mwPlannedTxt">MW Planned</label>
          <input class="form-control" type="number" pInputText id="mwPlannedTxt"
                 [(ngModel)]="data.mwPlanned" name="mwPlanned"/>
        </div>
        <div class="form-group">
          <label for="dhlTxt">Data Hall Live</label>
          <input class="form-control" type="number" pInputText id="dhlTxt"
                 [(ngModel)]="data.dataHallLive" name="dhl"/>
        </div>
        <div class="form-group">
          <label for="buildingAreaTxt">Building Area</label>
          <input class="form-control" type="number" pInputText id="buildingAreaTxt"
                 [(ngModel)]="data.buildingAreaTotal" name="buildingArea"/>
        </div>
        <div class="form-group">
          <label for="fyoTxt">First Year Op.</label>
          <input class="form-control" pInputText id="fyoTxt" maxlength="4" onlyNumber
                 [(ngModel)]="data.firstYearOp" name="fop"/>
        </div>
        <div class="form-group">
          <label for="siteType">Site Type</label>
          <p-dropdown id="siteType" placeholder="Select Site Type" [options]="siteTypes" [(ngModel)]="data.siteType"
            name="siteType"
            [style]="{ width: '100%' }"
          ></p-dropdown>
        </div>
        <div class="form-group">
          <label for="productType">Product Type</label>
          <p-dropdown id="productType" placeholder="Select Product Type" [options]="productTypes" [(ngModel)]="data.productType"
            name="productType"
            [style]="{ width: '100%' }"
            (onChange)="onProductTypeChange($event)"
          ></p-dropdown>
        </div>
        <div class="form-group" *ngIf="data.productType === 'Availability Zone'">
          <label for="availabilityZoneId">Availability Zone ID</label>
          <div class="cb-input-container">
            <div class="control-container">
              <div class="row">
                <div class="column col-lg-12 col-xl-9">
                  <p-dropdown *ngIf="!isAddingNewAZ" placeholder="Select Availability Zone ID" [options]="availabilityZoneIds"
                              [(ngModel)]="selectedAZId" [required]="true"
                              name="exAZId" [style]="{'width':'100%'}"></p-dropdown>
                  <input class="form-control" *ngIf="isAddingNewAZ" type="text" pInputText id="newAZIdTxt"
                         [(ngModel)]="data.availabilityZoneId" name="newAZId" required/>
                </div>
                <div class="column col-lg-12 col-xl-3 add-cb">
                  <input type="checkbox" [(ngModel)]="isAddingNewAZ" name="addNewAZCb" id="addNewAZCb"/>
                  <label for="addNewAZCb">Add New AZ</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="sellableITLoad">Sellable IT Load</label>
          <input class="form-control" type="number" pInputText id="sellableITLoad"
                 [(ngModel)]="data.sellableITLoad" name="sellableITLoad"/>
        </div>
        <div class="form-group">
          <label for="buildingTxt">Campus</label>
          <input class="form-control" type="text" pInputText id="campusTxt"
                 [(ngModel)]="data.campus" name="campus"/>
        </div>
        <div class="form-group">
          <label>Notes</label>
          <textarea class="form-control" name="notes" id="notes" pInputTextarea [(ngModel)]="data.notes">
          </textarea>
        </div>
        <div style="margin-top: 10px; float: right;" class="btn-toolbar">
          <p-button styleClass="p-button-primary primary p-button-sm" [label]="isModifying ? 'Modify' : 'Add Marker'"
                    [disabled]="!dataForm.form.valid"
                    (onClick)="saveNewMarker()"></p-button>
          <p-button styleClass="p-button-primary secondary p-button-sm" [label]="'Cancel'"
                    (onClick)="cancel()"></p-button>
        </div>
        
      </div>
    </div>
  </form>
</div>