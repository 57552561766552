<div class="az-popup-container">
    <div class="popup-header">
      <span class="popup-title">{{provider}}</span>
    </div>
    <span class="az-id">AZ Id: {{availabilityZoneId}}</span>

  
    <div class="metrics-section">
      <div class="metric">
        <span class="label">Live:</span>
        <span class="value">{{totalMWLive | number:'1.0-0'}}</span>
      </div>
      <div class="metric">
        <span class="label">Under Construction:</span>
        <span class="value">{{totalMWUC | number:'1.0-0'}}</span>
      </div>
      <div class="metric">
        <span class="label">Planned:</span>
        <span class="value">{{totalMWPlanned | number:'1.0-0'}}</span>
      </div>
      <div class="metric">
        <span class="label">MW Total:</span>
        <span class="value">{{totalMW | number:'1.0-0'}}</span>
      </div>
    </div>
  
    <div class="datacenters-section">
      <h6>Data Centers in this AZ:</h6>
      <div class="datacenter-list">
        <div *ngFor="let dc of datacenters" 
             class="datacenter-item"
             (click)="zoomToDatacenter(dc)">
          <span class="dc-name">{{dc.building || 'Unnamed DC'}}</span>
          <span class="dc-metrics">
            {{dc.mwLive}}/{{dc.mwUC}}/{{dc.mwPlanned}} MW
          </span>
        </div>
      </div>
    </div>
  </div>