import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { Datacenter, DatacenterBasicInfo } from '../../core/model/datacenter';
import {getFromDataList} from "../../core/util/transform.util";
import * as turf from '@turf/turf';
import {Store} from "@ngrx/store";

import { MapConfigSelector } from 'src/app/core/ngrx/selectors/map-config.selector';
import { Actions, ofType } from '@ngrx/effects';
import * as _ from 'lodash';
import { Subscription, Subject } from 'rxjs';
import { mapConfigActions } from 'src/app/core/ngrx/actions';
import { takeUntil } from 'rxjs/operators';
import { MapService } from '../../core/service/map.service';


@Component({
  selector: 'app-availability-zone-circle',
  templateUrl: './availability-zone-circle.component.html'
})
export class AvailabilityZoneCircleComponent implements OnInit, OnDestroy {
    private toggleSubscription: Subscription;
    private destroy$ = new Subject<void>();
    private static idCounter = 0;
    private currentLayerIds: string[] = [];
    private datacenterLayerIds: Map<string, string> = new Map();
    private map: mapboxgl.Map;

    constructor(
        private mapConfigSelector: MapConfigSelector,
        private readonly store: Store,
        private actions$: Actions,
        private cd: ChangeDetectorRef,
        @Inject(MapService) private mapService: MapService
    ) {
        this.map = this.mapService.map;
    }

  datacentersToDisplay: DatacenterBasicInfo[] = [];
  alreadyCentered: boolean = false;
  private azVisibilityMap: Map<string, boolean> = new Map();

  getSourceId(item: DatacenterBasicInfo, type: string, index: number): string {
    const key = `${item.locationId}-${item.availabilityZoneId}`;
    const baseId = this.datacenterLayerIds.get(key);
    const uniqueId = `${baseId}-${type}-${index}`;
    this.currentLayerIds.push(uniqueId);
    return uniqueId;
  }

  ngOnInit() {

    this.toggleSubscription = this.actions$.pipe(
      ofType(mapConfigActions.toggleAZVisibility)
    ).subscribe(action => {
      const key = `${action.provider} - ${action.azId}`;
      this.azVisibilityMap.set(key, action.visible);
    });
    // Update DC Color
    this.mapConfigSelector.getDatacenters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(datacenters => {
        this.clearExistingLayers();
        this.datacenterLayerIds.clear();

        this.datacentersToDisplay = datacenters.filter(dc => 
          dc.productType === 'Availability Zone'
        ).map(dc => {
          const key = `${dc.locationId}-${dc.availabilityZoneId}`;
          if (!this.datacenterLayerIds.has(key)) {
            this.datacenterLayerIds.set(key, `az-${Date.now()}-${Math.random()}`);
          }
          return dc;
        });
        
        this.cd.detectChanges();
      });
  }

  private clearExistingLayers() {
    if (!this.map?.getStyle()) return;

    this.currentLayerIds.forEach(id => {
      try {
        // Remove each layer
        if (this.map.getStyle().layers.find(layer => layer.id === id)) {
          this.map.removeLayer(id);
        }
        const sourceId = id.replace('-layer', '-source');
        if (this.map.getStyle().sources[sourceId]) {
          this.map.removeSource(sourceId);
        }
      } catch (error) {
        console.warn(`Error - removing layer ${id}:`, error);
      }
    });
    this.currentLayerIds = [];
  }

  isAZVisible(item: any): boolean {
    const key = `${item.provider} - ${item.availabilityZoneId}`;
    return this.azVisibilityMap.get(key) ?? true;
  }

  drawAvailabilityZoneCircle(datacenter: DatacenterBasicInfo) {
    if (datacenter.productType === 'Availability Zone') {
      const point = turf.point([datacenter.lon, datacenter.lat]);
      const circle = turf.circle(point, 10, {
        units: 'kilometers',
        properties: {
          provider: datacenter.provider,
          azId: datacenter.availabilityZoneId,
          color: datacenter.color
        }
      });
      
      return circle;
    }
    return null;
  }

  drawAvailabilityZoneCenter(datacenter) {
    return {
      type: 'FeatureCollection' as const,
      features: [{
        type: 'Feature' as const,
        geometry: {
          type: 'Point' as const,
          coordinates: [datacenter.lon, datacenter.lat]
        },
        properties: {
          azId: datacenter.availabilityZoneId
        }
      }]
    };
  }

  hasDatacenterChanged(datacenters: DatacenterBasicInfo[]) {

    for (let dc of datacenters) {
      let toCompare = getFromDataList(this.datacentersToDisplay as Datacenter[], dc);
      if (toCompare == null) {
        return true;
      } else {
        const areEquals = _.isEqual(dc, toCompare);
        if (!areEquals) {
          return true;
        }
      }
    }
    return false;
  }

  getGeoJsonSource(item: DatacenterBasicInfo, type: string, index: number, data: any) {
    return {
      type: 'geojson' as 'custom',
      id: this.getSourceId(item, type, index),
      data: data
    };
  }

  ngOnDestroy() {
    if (this.toggleSubscription) {
      this.toggleSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}