import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {MapConfigStateService} from "../../service/map-config-state.service";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {mapFilesActions} from "../actions"
import {Injectable} from "@angular/core";
import {MapFileService} from "../../service/map-file.service";
import {State as mapConfigState} from "../reducers/map-config.reducer";
import * as _ from "lodash";
import {MapFile} from "../../model/map-file";

@Injectable()
export class MapFilesEffects {
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    public mapConfigState: MapConfigStateService,
    private mapFileService: MapFileService
  ) {
  }

  loadMapFiles = createEffect(() =>
    this.actions$.pipe(
      ofType(mapFilesActions.loadFiles),
      withLatestFrom(this.store),
      exhaustMap(([action, store]) => this.mapFileService.getMapFiles().pipe(
        map(response => {
          const currentMapConfigState = (store as any).mapConfig as mapConfigState;
          if (currentMapConfigState.userMap && currentMapConfigState.userMap.files) {
            response.forEach((mapFile: MapFile) => {
              if (currentMapConfigState.userMap.files.indexOf(mapFile.fileId) != -1) {
                mapFile.selected = true;
              }
            });
          }
          return mapFilesActions.loadFilesSuccess({mapFiles: response as any})
        }),
        catchError((error: any) => of(mapFilesActions.loadFilesFailure({msg: "error"})))
      )))
  );

  shareMapFile = createEffect(() =>
    this.actions$.pipe(
      ofType(mapFilesActions.shareMapFile),
      exhaustMap(action => 
        this.mapFileService.shareMapFile(action.fileId, action.userIds).pipe(
          map(() => {
            return mapFilesActions.shareMapFileSuccess();
          }),
          catchError((error) => of(mapFilesActions.shareMapFileFailure({ error: 'Error sharing file' })))
        )
      )
    )
  );
}
