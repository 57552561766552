import {AppError} from "../../model/app-error";
import {MapFile} from "../../model/map-file";
import {Action, createReducer, on} from "@ngrx/store";
import {mapFilesActions} from "../actions";
import {FIBER_TYPE, LAND_TYPE, POWER_TYPE} from "../../consts/file.constants";
import * as _ from "lodash";
import {State as mapConfigState} from "../reducers/map-config.reducer";

export interface State {
  mapFiles: MapFile[],
  fiberFiles: MapFile[],
  landFiles: MapFile[],
  powerFiles: MapFile[],
  error: AppError
}

export const initialState: State = {
  mapFiles: [],
  fiberFiles: [],
  landFiles: [],
  powerFiles: [],
  error: undefined,
}

const mapFilesReducer = createReducer(initialState,
  on(mapFilesActions.loadFilesSuccess, (state, action) => {
    return {
      ...initialState,
      mapFiles: action.mapFiles,
      fiberFiles: action.mapFiles.filter(file => file.fileType == FIBER_TYPE),
      landFiles: action.mapFiles.filter(file => file.fileType == LAND_TYPE),
      powerFiles: action.mapFiles.filter(file => file.fileType == POWER_TYPE)
    };
  }),
  on(mapFilesActions.fileToggle, (state, action) => {
    const mapFiles: MapFile[] = _.cloneDeep(state.mapFiles);
    const existingMapFile = mapFiles.find((mapFile: MapFile) => mapFile.fileId == action.mapFile.fileId);
    if (existingMapFile) {
      existingMapFile.selected = action.mapFile.selected;
    }
    return {
      ...initialState,
      mapFiles: mapFiles,
      fiberFiles: mapFiles.filter(file => file.fileType == FIBER_TYPE),
      landFiles: mapFiles.filter(file => file.fileType == LAND_TYPE),
      powerFiles: mapFiles.filter(file => file.fileType == POWER_TYPE)
    };
  })
);

export function reducer(state: State | undefined, action: Action): any {
  return mapFilesReducer(state, action);
}
