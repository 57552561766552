<div *ngFor="let item of datacentersToDisplay; let i = index">
  <!-- Availability Zone Circle -->
  <mgl-layer
    [id]="getSourceId(item, 'circle', i)"
    type="fill"
    [source]="{
      type: 'geojson',
      data: drawAvailabilityZoneCircle(item)
    }"
    [layout]="{
      'visibility': isAZVisible(item) ? 'visible' : 'none'
    }"
    [paint]="{
      'fill-color': item.color,
      'fill-opacity': 0.02
    }">
  </mgl-layer>

  <!-- Circle line layer -->
  <mgl-layer
    [id]="getSourceId(item, 'line', i)"
    type="line"
    [source]="{
      type: 'geojson',
      data: drawAvailabilityZoneCircle(item)
    }"
    [layout]="{
      'line-cap': 'round',
      'line-join': 'round',
      'visibility': isAZVisible(item) ? 'visible' : 'none'
    }"
    [paint]="{
      'line-color': item.color,
      'line-width': 2,
      'line-dasharray': [0.2, 4]
    }">
  </mgl-layer>

  <!-- Label layer -->
  <mgl-layer
    [id]="getSourceId(item, 'label', i)"
    type="symbol"
    [source]="{
      type: 'geojson',
      data: drawAvailabilityZoneCenter(item)
    }"
    [layout]="{
      'text-field': item.availabilityZoneId,
      'text-size': 14,
      'text-anchor': 'center',
      'symbol-placement': 'point',
      'text-offset': [0, 2],
      'visibility': isAZVisible(item) ? 'visible' : 'none'
    }"
    [paint]="{
      'text-color': item.color,
      'text-halo-color': '#FFFFFF',
      'text-halo-width': 1.5
    }">
  </mgl-layer>
</div>