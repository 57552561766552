import {createAction, props} from "@ngrx/store";
import {MapLine, MapPolygon, Nestable} from "../../model/summary";
import {DistanceUnits, UserMap} from "../../model/user-map";
import {Datacenter, DatacenterBasicInfo} from "../../model/datacenter";
import {MapObject} from "../../model/map-object";

export const LOAD_MAP_SOURCE = '[MapConfig Page] Load Map Source';
export const HIDE_LINE_FEATURES = '[MapConfig Page] Hide Line Features';
export const SHOW_LINE_FEATURES = '[MapConfig Page] Show Line Features';
export const HIDE_POLYGON_FEATURES = '[MapConfig Page] Hide Polygon Features';



export const SHOW_POLYGON_FEATURES = '[MapConfig Page] Show Polygon Features';
export const UPDATE_DISTANCE_LABEL = '[MapConfig Page] Update Distance Label';
export const UPDATE_AREA_DATA = '[MapConfig Page] Update Area data';
export const UPDATE_POLYGON_LABEL = '[MapConfig Page] Update Polygon Label';
export const REMOVE_DRAWING = '[MapConfig Page] Remove Line';

export const UPDATE_INFO_SQUARE = '[MapConfig Page] Update Info Square';

export const SAVE_MAP = '[MapConfig Page] Save Map';
export const SAVE_PARTIAL_MAP = '[MapConfig Page] Save Partial Map';
export const SAVE_MAP_SUCCESS = '[MapConfig Page] Save Map Success';
export const SAVE_MAP_ERROR = '[MapConfig Page] Save Map Error';

export const MERGE_MAP = '[MapConfig Page] Merge Map';
export const MERGE_MAP_SUCCESS = '[MapConfig Page] Merge Map Success';
export const MERGE_MAP_ERROR = '[MapConfig Page] Merge Map Error';

export const MAP_STYLE_CHANGED = '[MapConfig Page] Map Style Changed';


export const POLYGON_SELECTED = '[MapConfig Page] Market Selected';
export const MARKET_SELECTED = '[MapConfig Page] Polygon Selected';
export const POPUP_SAVED = '[MapConfig Page] Popup Saved';
export const POPUP_CANCELLED = '[MapConfig Page] Popup Cancelled';
export const LOAD_USER_MAPS = '[MapDataConfig Page] Load User Maps';
export const LOAD_USER_MAPS_SUCCESS = '[MapDataConfig Page] Load User Maps Success';
export const LOAD_USER_MAPS_ERROR = '[MapDataConfig Page] Load User Maps Error';
export const UPLOAD_CSV = '[MapDataConfig Page] Upload CSV';
export const UPLOAD_CSV_SUCCESS = '[MapDataConfig Page] Upload CSV Success';
export const UPLOAD_CSV_ERROR = '[MapDataConfig Page] Upload CSV Error';
export const COPY_FROM_MAP = '[MapDataConfig Page] Copy from Map';
export const COPY_FROM_MAP_SUCCESS = '[MapDataConfig Page] Copy from Map Sucess';
export const COPY_FROM_MAP_ERROR = '[MapDataConfig Page] Copy from Map Error';

export const LOAD_USER_MAP = '[MapConfig Page] Load User Map';
export const LOAD_USER_MAP_SUCCESS = '[MapConfig Page] Load User Map Success';
export const LOAD_USER_MAP_ERROR = '[MapConfig Page] Load User Map Error';

export const SELECTED_PROVIDERS_CHANGED = '[MapConfig Page] Selected Providers Changed';

export const ADDED_DATA_CENTER = '[MapConfig Page] Added Datacenter';
export const MODIFIED_CENTER = '[MapConfig Page] Modified Datacenter';
export const REMOVED_CENTER = '[MapConfig Page] Removed Datacenter';
export const CHANGED_DATACENTER_COLOR = '[MapConfig Change Datacenter Color] Changed Datacenter Color';

export const NUKE_STORE = '[MapConfig Page] Nuke Store';
export const DATACENTER_HOVER_CHANGE = '[MapConfig Page] Datacenter Hover Change';

export const TOGGLE_DATACENTERS = '[MapConfig Page] Toggle Datacenters';

export const ZOOM = '[MapConfig Page] Map Zoomed';

export const INCREASE_MARKERS_SIZE = '[MapConfig Page] Map Markers Increase Size';
export const REDUCE_MARKERS_SIZE = '[MapConfig Page] Map Markers Reduce Size';
export const RESET_MARKERS_SIZE = '[MapConfig Page] Map Markers Reser Size';

export const MW_THRESHOLD_CHANGE = '[MapConfig Page] Mw Threshold Change';
export const DISTANCE_UNITS_CHANGED = '[MapConfig Page] Distance Units Changed';

export const TOGGLE_PROVIDER_VISIBILITY = '[MapConfig Page] Toggle Provider Visibility';
export const TOGGLE_MARKERS_DRAG = '[MapConfig Page] Toggle Markers Drag';

export const ZOOM_END = '[MapConfig Page] Zoom end';
export const POPUP_DISPLAYED = '[MapConfig Page] Popup Displayed';

export const loadMapSource = createAction(
  LOAD_MAP_SOURCE,
  props<{ markets: Nestable[], mapLines: MapLine[], mapPolygons: MapPolygon[] }>()
);

export const hideLineFeatures = createAction(
  HIDE_LINE_FEATURES,
  props<{ drawControlFeatureCollection: GeoJSON.FeatureCollection }>()
);

export const hidePolygonFeatures = createAction(
  HIDE_POLYGON_FEATURES,
  props<{ drawControlFeatureCollection: GeoJSON.FeatureCollection }>()
);

export const showLineFeatures = createAction(
  SHOW_LINE_FEATURES
);

export const showPolygonFeatures = createAction(
  SHOW_POLYGON_FEATURES
);

export const updateDistanceLabel = createAction(
  UPDATE_DISTANCE_LABEL,
  props<{ feature: GeoJSON.Feature, drawControlFeatureCollection: GeoJSON.FeatureCollection }>()
);

export const updateAreaData = createAction(
  UPDATE_AREA_DATA,
  props<{ areas: Nestable[] }>()
);

export const updatePolygonLabel = createAction(
  UPDATE_POLYGON_LABEL,
  props<{ feature: GeoJSON.Feature, drawControlFeatureCollection: GeoJSON.FeatureCollection }>()
);

export const removeDrawing = createAction(
  REMOVE_DRAWING,
  props<{ feature: GeoJSON.Feature, drawControlFeatureCollection: GeoJSON.FeatureCollection }>()
);

export const savePartialMap = createAction(
  SAVE_PARTIAL_MAP,
  props<{ userMap: UserMap }>()
);

export const saveMap = createAction(
  SAVE_MAP
);

export const saveMapSuccess = createAction(
  SAVE_MAP_SUCCESS,
  props<{ userMap: UserMap }>()
);

export const saveMapError = createAction(
  SAVE_MAP_ERROR,
  props<{ msg: string }>()
);

export const mergeMap = createAction(
  MERGE_MAP,
  props<{ maps: UserMap[], userMap: UserMap, objectsToAdd: MapObject[] }>()
);

export const mergeMapSuccess = createAction(
  MERGE_MAP_SUCCESS,
  props<{ userMap: UserMap }>()
);

export const mergeMapError = createAction(
  MERGE_MAP_ERROR,
  props<{ msg: string }>()
);

export const mapStyleChanged = createAction(
  MAP_STYLE_CHANGED,
  props<{ layerId: String }>()
);

export const polygonSelected = createAction(
  POLYGON_SELECTED,
  props<{ featureId: string, isModifying: boolean }>()
);

export const marketSelected = createAction(
  MARKET_SELECTED,
  props<{ feature: any, drawControlFeatureCollection: GeoJSON.FeatureCollection, clickLocation: any, marketLabel: string }>()
);

export const popupSaved = createAction(
  POPUP_SAVED,
  props<{ polygonData: MapPolygon }>()
);

export const popupCancelled = createAction(
  POPUP_CANCELLED
);

export const loadUserMaps = createAction(
  LOAD_USER_MAPS,
);

export const loadUserMapsSuccess = createAction(
  LOAD_USER_MAPS_SUCCESS,
  props<{ userMaps: UserMap[] }>()
);

export const loadUserMapsError = createAction(
  LOAD_USER_MAPS_ERROR,
  props<{ msg: string }>()
);

export const uploadCsv = createAction(
  UPLOAD_CSV,
  props<{ mapId: string, data: string[] }>()
);

export const uploadCsvSuccess = createAction(
  UPLOAD_CSV_SUCCESS,
  props<{ userMap: UserMap }>()
);

export const uploadCsvError = createAction(
  UPLOAD_CSV_ERROR,
  props<{ msg: string }>()
);

export const copyFromMap = createAction(
  COPY_FROM_MAP,
  props<{ mapId: string, maptoCopy: string }>()
);

export const copyFromMapSuccess = createAction(
  UPLOAD_CSV_SUCCESS,
  props<{ userMap: UserMap }>()
);

export const copyFromMapError = createAction(
  UPLOAD_CSV_ERROR,
  props<{ msg: string }>()
);


export const loadUserMap = createAction(
  LOAD_USER_MAP,
  props<{ userId: string, full: boolean, mapId: string }>()
);

export const loadUserMapSuccess = createAction(
  LOAD_USER_MAP_SUCCESS,
  props<{ userMap: UserMap, providers: Nestable[], readOnly: boolean }>()
);

export const loadUserMapError = createAction(
  LOAD_USER_MAP_ERROR,
  props<{ msg: string }>()
);

export const selectedProvidersChanged = createAction(
  SELECTED_PROVIDERS_CHANGED,
  props<{ selectedProviders: Nestable[] }>()
);

export const addedDataCenter = createAction(
  ADDED_DATA_CENTER,
  props<{ datacenterBasicInfo: DatacenterBasicInfo }>()
);

export const modifiedDataCenter = createAction(
  MODIFIED_CENTER,
  props<{ datacenterBasicInfo: DatacenterBasicInfo }>()
);

export const removedDataCenter = createAction(
  REMOVED_CENTER,
  props<{ datacenterBasicInfo: DatacenterBasicInfo }>()
);

export const nukeStore = createAction(
  NUKE_STORE
);

export const datacenterHoverChange = createAction(
  DATACENTER_HOVER_CHANGE,
  props<{ hover: boolean, value: String, isProvider: boolean }>()
);

export const toggleDatacenters = createAction(
  TOGGLE_DATACENTERS,
  props<{ hideDatacenters: boolean, auxId: String }>()
);

export const zoom = createAction(
  ZOOM,
  props<{ zoom: number }>()
);

export const increaseMarkersSize = createAction(
  INCREASE_MARKERS_SIZE
);

export const reduceMarkersSize = createAction(
  REDUCE_MARKERS_SIZE
);

export const resetMarkersSize = createAction(
  RESET_MARKERS_SIZE,
  props<{ zoom: number }>()
);

export const mwThresholdChange = createAction(
  MW_THRESHOLD_CHANGE,
  props<{ value: number }>()
);

export const toggleProviderVisibility = createAction(
  TOGGLE_PROVIDER_VISIBILITY,
  props<{ provider: Nestable[], visible: boolean }>()
);

export const toggleMarkersDrag = createAction(
  TOGGLE_MARKERS_DRAG
);

export const zoomEnd = createAction(
  ZOOM_END,
  props<{ datacenters: Datacenter[] }>()
);

export const popupDisplayed = createAction(
  POPUP_DISPLAYED
);

export const changedDatacenterColor = createAction(
  CHANGED_DATACENTER_COLOR,
  props<{ color: String, provider: String }>()
);

export const distanceUnitsChanged = createAction(
  DISTANCE_UNITS_CHANGED,
  props<{ units: DistanceUnits }>()
);

export const updateInfoSquare = createAction(
  UPDATE_INFO_SQUARE
);

export const updateMarkerColor = createAction(
  '[Map Config] Update Marker Color',
  props<{ datacenter: DatacenterBasicInfo; newProvider: string }>()
);

export const toggleInfoLayer = createAction(
  '[Map Config] Toggle Info Layer',
  props<{ visible: boolean }>()
);

export const toggleAZVisibility = createAction(
  '[Map Config] Toggle AZ Visibility',
  props<{ provider: string; azId: string; visible: boolean }>()
);

export const updateAZColor = createAction(
  '[Map Config] Update AZ Color',
  props<{ provider: string; azId: string; color: string }>()
);