import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Datacenter } from '../../core/model/datacenter';
import { mapConfigActions } from '../../core/ngrx/actions';
import { MapConfigSelector } from '../../core/ngrx/selectors/map-config.selector';
import { AZLayer } from '../../core/model/map-config';
import { MapDisplayComponent } from '../map-display/map-display.component';

export interface AZGroup {
  azId: string;
  layers: AZLayer[];
}

@Component({
  selector: 'app-availability-zones-sidebar',
  templateUrl: './availability-zones-sidebar.component.html',
  styleUrls: ['./availability-zones-sidebar.component.scss']
})
export class AvailabilityZonesSidebarComponent implements OnInit {
  groupedLayers$: Observable<AZGroup[]>;
  public changingColorProvider: string = null;
  public changingColor: string = null;


  constructor(
    private store: Store<any>,
    private mapConfigSelector: MapConfigSelector,
    private map: MapDisplayComponent
  ) {}

  ngOnInit() {
    this.groupedLayers$ = this.mapConfigSelector.getDatacenters()
      .pipe(
        map((datacenters: Datacenter[]) => {
          const layersByAzId = new Map<string, AZLayer[]>();
          
          datacenters
            .filter(dc => dc.productType === 'Availability Zone' && dc.availabilityZoneId)
            .forEach(dc => {
              const key = dc.availabilityZoneId;
              if (!layersByAzId.has(key)) {
                layersByAzId.set(key, []);
              }
              
              const existingLayer = layersByAzId.get(key)
                .find(layer => layer.provider === dc.provider);
              
              if (!existingLayer) {
                layersByAzId.get(key).push({
                  provider: dc.provider,
                  azId: dc.availabilityZoneId,
                  isVisible: true,
                  color: dc.color
                });
              }
            });

          return Array.from(layersByAzId.entries())
            .map(([azId, layers]) => ({
              azId,
              layers: layers.sort((a, b) => a.provider.localeCompare(b.provider))
            }))
            .sort((a, b) => a.azId.localeCompare(b.azId));
        })
      );
  }

  toggleAZVisibility(layer: AZLayer) {
    const newVisibility = !layer.isVisible;

    this.mapConfigSelector.getDatacenters()
      .pipe(take(1))
      .subscribe(datacenters => {
        const azDatacenters = datacenters.filter(dc => 
          dc.productType === 'Availability Zone' && 
          dc.provider === layer.provider && 
          dc.availabilityZoneId === layer.azId
        );

        azDatacenters.forEach(dc => {
          const layerIds = [
            `az-circle-fill-${dc.locationId || dc.tempId}`,
            `az-circle-${dc.locationId || dc.tempId}`,
            `az-circle-label-${dc.locationId || dc.tempId}`
          ];

          layerIds.forEach(layerId => {
            if (this.map && this.map.map && this.map.map.getLayer(layerId)) {
              this.map.map.setLayoutProperty(
                layerId,
                'visibility',
                newVisibility ? 'visible' : 'none'
              );
            }
          });
        });
      });

    layer.isVisible = newVisibility;

    this.store.dispatch(mapConfigActions.toggleAZVisibility({
      provider: layer.provider,
      azId: layer.azId,
      visible: newVisibility
    }));
  }

  colorChanged(event, layer) {
    this.changingColorProvider = layer.provider;
    this.changingColor = event.value;
  }

  onHide() {
    if (this.changingColorProvider && this.changingColor) {
      this.store.dispatch(mapConfigActions.changedDatacenterColor({
        provider: this.changingColorProvider,
        color: this.changingColor
      }));
      this.changingColorProvider = null;
      this.changingColor = null;
    }
  }
  
}