<div style="height: 644px; min-height: 644px;overflow: hidden;">
  <p-table id="filesList" [scrollable]="true"
           scrollHeight="flex" [value]="_mapFiles"
           [lazy]="false" dataKey="fileId">
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2" style="width:100%;height: 20px;"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mapFile>
      <tr [pSelectableRow]="mapFile" class="p-selectable-row">
        <td style="width:30%;">
          <span *ngIf="mapFile.selected"><a (click)="toggleSelect(mapFile)"
                                             style="cursor:pointer;text-decoration: none;margin-top: 3px;"
                                             class="pi pi-eye"></a></span>
          <span *ngIf="!mapFile.selected"><a (click)="toggleSelect(mapFile)"
                                            style="cursor:pointer;text-decoration: none;margin-top: 3px;"
                                            class="pi pi-eye-slash"></a></span>
          &nbsp;
        </td>
        <td style="width:70%; text-align: left;">
          {{mapFile.fileName}}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
